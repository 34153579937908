import { Footer } from "../../Components/Footer"
import Header from "../../Components/Header"
import Contact from "../../Components/Contact"


export const ContactUs = () => {

    return (
        <>
        <Header />
        <Contact />
        <Footer />
        </>
        
    )
}